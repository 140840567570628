// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
/* tslint:disable */
/* This is generated by ts_routes-rails */

export type ScalarType = string | number | boolean;

function $buildOptions(options: any, names: string[]): string {
  if (options) {
    let anchor = "";
    const q = [] as string[];
    for (const key of Object.keys(options)) {
      if (names.indexOf(key) !== -1) {
        // the key is already consumed
        continue;
      }

      const value = options[key];

      if (key === "anchor") {
        anchor = `#${$encode(value)}`;
      } else {
        $buildQuery(q, key, value);
      }
    }
    return (q.length > 0 ? "?" + q.join("&") : "") + anchor;
  } else {
    return "";
  }
}

function $buildQuery(q: string[], key: string, value: any) {
  if ($isScalarType(value)) {
    q.push(`${$encode(key)}=${$encode(value)}`);
  } else if (Array.isArray(value)) {
    for (const v of value) {
      $buildQuery(q, `${key}[]`, v);
    }
  } else if (value != null) {
    // i.e. non-null, non-scalar, non-array type
    for (const k of Object.keys(value)) {
      $buildQuery(q, `${key}[${k}]`, value[k]);
    }
  }
}

function $encode(value: any): string {
  return encodeURIComponent(value);
}

function $isScalarType(value: any): value is ScalarType {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  );
}

function $isPresent(value: any): boolean {
  return value != null && ("" + value).length > 0;
}

function $hasPresentOwnProperty(options: any, key: string): boolean {
  return options && options.hasOwnProperty(key) && $isPresent(options[key]);
}

/** /admin/:stage_parent/:pipeline_name/:current_tab(.:format) */
export function pipelineEditPath(stage_parent: ScalarType, pipeline_name: ScalarType, current_tab: ScalarType, options?: object): string {
  return "/go" + "/" + "admin" + "/" + stage_parent + "/" + pipeline_name + "/" + current_tab + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["stage_parent","pipeline_name","current_tab","format"]);
}

/** /admin/pipeline_group/:group_name/edit(.:format) */
export function pipelineGroupEditPath(group_name: ScalarType, options?: object): string {
  return "/go" + "/" + "admin" + "/" + "pipeline_group" + "/" + group_name + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["group_name","format"]);
}

/** /admin/pipeline_group/:group_name(.:format) */
export function pipelineGroupUpdatePath(group_name: ScalarType, options?: object): string {
  return "/go" + "/" + "admin" + "/" + "pipeline_group" + "/" + group_name + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["group_name","format"]);
}

/** /analytics(.:format) */
export function analyticsPath(options?: object): string {
  return "/go" + "/" + "analytics" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /analytics/:plugin_id/:type/:id(.:format) */
export function showAnalyticsPath(plugin_id: ScalarType, type: ScalarType, id: ScalarType, options?: object): string {
  return "/go" + "/" + "analytics" + "/" + plugin_id + "/" + type + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["plugin_id","type","id","format"]);
}

/** /api/notification_filters */
export function apiv1NotificationFiltersPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "notification_filters" + $buildOptions(options, []);
}

/** /api/notification_filters/:id */
export function apiv1NotificationFilterPath(id: ScalarType, options?: object): string {
  return "/go" + "/" + "api" + "/" + "notification_filters" + "/" + id + $buildOptions(options, ["id"]);
}

/** /api/admin/templates/:template_name/authorization */
export function apiv1AdminTemplatesPath(template_name: ScalarType, options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "templates" + "/" + template_name + "/" + "authorization" + $buildOptions(options, ["template_name"]);
}

/** /api/admin/internal/material_test */
export function apiv1AdminInternalMaterialTestPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "admin" + "/" + "internal" + "/" + "material_test" + $buildOptions(options, []);
}

/** /api/version_infos/stale */
export function apiv1StaleVersionInfoPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "version_infos" + "/" + "stale" + $buildOptions(options, []);
}

/** /api/version_infos/latest_version */
export function apiv1LatestVersionInfoPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "version_infos" + "/" + "latest_version" + $buildOptions(options, []);
}

/** /api/version_infos/go_server */
export function apiv1UpdateServerVersionInfoPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "version_infos" + "/" + "go_server" + $buildOptions(options, []);
}

/** /api/*url */
export function apiv1Path(url: ScalarType[], options?: object): string {
  return "/go" + "/" + "api" + "/" + url.map((part) => $encode(part)).join("/") + $buildOptions(options, ["url"]);
}

/** /api/config/pipeline_groups(.:format) */
export function pipelineGroupConfigListApiPath(options?: object): string {
  return "/go" + "/" + "api" + "/" + "config" + "/" + "pipeline_groups" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter(.:format) */
export function stageDetailTabDefaultPath(pipeline_name: ScalarType, pipeline_counter: ScalarType, stage_name: ScalarType, stage_counter: ScalarType, options?: object): string {
  return "/go" + "/" + "pipelines" + "/" + pipeline_name + "/" + pipeline_counter + "/" + stage_name + "/" + stage_counter + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["pipeline_name","pipeline_counter","stage_name","stage_counter","format"]);
}

/** /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter/overview(.:format) */
export function stageDetailTabOverviewPath(pipeline_name: ScalarType, pipeline_counter: ScalarType, stage_name: ScalarType, stage_counter: ScalarType, options?: object): string {
  return "/go" + "/" + "pipelines" + "/" + pipeline_name + "/" + pipeline_counter + "/" + stage_name + "/" + stage_counter + "/" + "overview" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["pipeline_name","pipeline_counter","stage_name","stage_counter","format"]);
}

/** /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter/pipeline(.:format) */
export function stageDetailTabPipelinePath(pipeline_name: ScalarType, pipeline_counter: ScalarType, stage_name: ScalarType, stage_counter: ScalarType, options?: object): string {
  return "/go" + "/" + "pipelines" + "/" + pipeline_name + "/" + pipeline_counter + "/" + stage_name + "/" + stage_counter + "/" + "pipeline" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["pipeline_name","pipeline_counter","stage_name","stage_counter","format"]);
}

/** /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter/materials(.:format) */
export function stageDetailTabMaterialsPath(pipeline_name: ScalarType, pipeline_counter: ScalarType, stage_name: ScalarType, stage_counter: ScalarType, options?: object): string {
  return "/go" + "/" + "pipelines" + "/" + pipeline_name + "/" + pipeline_counter + "/" + stage_name + "/" + stage_counter + "/" + "materials" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["pipeline_name","pipeline_counter","stage_name","stage_counter","format"]);
}

/** /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter/jobs(.:format) */
export function stageDetailTabJobsPath(pipeline_name: ScalarType, pipeline_counter: ScalarType, stage_name: ScalarType, stage_counter: ScalarType, options?: object): string {
  return "/go" + "/" + "pipelines" + "/" + pipeline_name + "/" + pipeline_counter + "/" + stage_name + "/" + stage_counter + "/" + "jobs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["pipeline_name","pipeline_counter","stage_name","stage_counter","format"]);
}

/** /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter/stats(.:format) */
export function stageDetailTabStatsPath(pipeline_name: ScalarType, pipeline_counter: ScalarType, stage_name: ScalarType, stage_counter: ScalarType, options?: object): string {
  return "/go" + "/" + "pipelines" + "/" + pipeline_name + "/" + pipeline_counter + "/" + stage_name + "/" + stage_counter + "/" + "stats" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["pipeline_name","pipeline_counter","stage_name","stage_counter","format"]);
}

/** /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter/stats_iframe(.:format) */
export function stageDetailTabStatsIframePath(pipeline_name: ScalarType, pipeline_counter: ScalarType, stage_name: ScalarType, stage_counter: ScalarType, options?: object): string {
  return "/go" + "/" + "pipelines" + "/" + pipeline_name + "/" + pipeline_counter + "/" + stage_name + "/" + stage_counter + "/" + "stats_iframe" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["pipeline_name","pipeline_counter","stage_name","stage_counter","format"]);
}

/** /pipelines/:pipeline_name/:pipeline_counter/:stage_name/:stage_counter/stage_config(.:format) */
export function stageDetailTabStageConfigPath(pipeline_name: ScalarType, pipeline_counter: ScalarType, stage_name: ScalarType, stage_counter: ScalarType, options?: object): string {
  return "/go" + "/" + "pipelines" + "/" + pipeline_name + "/" + pipeline_counter + "/" + stage_name + "/" + stage_counter + "/" + "stage_config" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["pipeline_name","pipeline_counter","stage_name","stage_counter","format"]);
}

/** /internal/pipelines/:pipeline_name/:pipeline_counter(.:format) */
export function internalStageDetailTabPath(pipeline_name: ScalarType, pipeline_counter: ScalarType, options?: object): string {
  return "/go" + "/" + "internal" + "/" + "pipelines" + "/" + pipeline_name + "/" + pipeline_counter + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["pipeline_name","pipeline_counter","format"]);
}
