// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "20.1.0",
  "buildNumber": "11114",
  "gitSha": "f2c1771e333ea92dae82cd78d2efa1310c1086f0",
  "fullVersion": "20.1.0-11114",
  "formattedVersion": "20.1.0 (11114-f2c1771e333ea92dae82cd78d2efa1310c1086f0)",
  "copyrightYear": "2020"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/20.1.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/20.1.0/${stripLeadingPrefix(suffix, '#')}`
}
    
